import {  useEffect } from 'react'
import back from "../../assets/contactBackground.png"
import company from "../../assets/company.jpg"
import './index.scss'

import 'antd/dist/antd.min.css';
//组件
import Swiper from "../../components/about/swiper.jsx"
import Banner from "../../components/Banner"
import backImage from "../../assets/Solution/top-img2.png"
import backpic from "../../assets/qiyegaikuo.jpg"
const backColor = {
  width: "100%",
    height: "440px",
    backgroundImage: `url(${backpic})`,
    backgroundSize: '100% 100%',
    paddingTop: '64px'
}

const bannerContent = {
  tit: '关于我们',
  cont: '心思远，慧所达,以数字技术为驱动 共建绿色、低碳、安全、 智慧家园新体系智科云鑫'
}
const backImg2 = {
  width: "100%",
  // height:'100%',
  backgroundImage: `url(${back})`,
  backgroundSize: '100% 100%',
}
const About = () => {
  // const carouselEL = useRef(null)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="EnterpriseProfile">
      <div style={backColor}>
        <Banner bannerContent={bannerContent} backImage={backImage} />
      </div>
      <div className='introdece'>
        <p className='title'>公司介绍</p>
        <div className='botContWrap'>
          <div className='contWrap'>
            <div className='words'>
              <p className='titWords'>助力打造绿色低碳经济，实现碳中和</p>
              <div className='line'> </div>
              <div className='tipWords'>
                智科云鑫科技有限公司是智慧城乡领域的创新实践机构。以“科技驱动全生命周期运营”为理念，以“安全、绿色、智能”为引领，为智慧城乡建设提供系统性的解决方案。通过策划、设计、建设、运营全生命周期的服务，打造低碳、绿色的智慧社区、智慧园区、智慧城管、智慧工厂、智慧乡村等标杆场景，致力智慧城乡发展。
                智科云鑫科技有限公司由连续创业者、天使投资人陈巍先生发起设立，技术核心小组由中科院、国家农业信息化工程技术研究中心多名博士组成，吸引多位来自世界500强和国内顶级 T 、科技企业的专业人才共同组建顶级团队。公司成立两年，通过政、产、学、研、金、服、用的整合形成全链路能力，现已有智慧社区、智慧人社、智慧学校等多个项目落地运营。
              </div>
            </div>
            <img src={company} alt="" />
          </div>
          <div className='wrap'>
            <div className='boxWrap' style={{ background: '#F2F5FA' }}></div>
            <div className='boxWrap'></div>
          </div>
        </div>

      </div>
      <div className='introdece' style={backImg2}>
        <p className='title' style={{color:' #0A1E2D'}}>荣誉资质</p>

        <Swiper/>
      </div>
    </div>
  );
}

export default About;
