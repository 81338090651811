import React, { Component } from 'react'
import './swiper.scss'
import img1 from "../../assets/About/1.jpg"
import img2 from "../../assets/About/2.jpg"
import img3 from "../../assets/About/3.png"
import img4 from "../../assets/About/4.png"
import img5 from "../../assets/About/5.png"
import img6 from "../../assets/About/6.png"
import img7 from "../../assets/About/7.png"
export default class CardCarousel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [img1, img2, img4, img5, img6, img7, img3],
            current: 1, // 轮播图当前 index
            itemWidth: 0, //  图片区域 宽度
            out: 3,  // 堆叠式 外部看到的数量 （左右各相同） 不限制 可以设置 0 或 大于轮播图数量
            offset: 80, // 堆叠式 外部偏移量 (产生堆叠效果)
        }
    }
    componentDidMount() {
        // 获取轮播图片区域宽度
        const width = this.refs.swiperitem.clientWidth
        this.setState({
            itemWidth: width
        })
    }
    // 上一张
    onPrev = (index) => {
        const length = this.state.list.length
        if (index - 1 < 0) {
            this.setState({
                current: length - 1
            })
        } else {
            this.setState({
                current: index - 1
            })
        }
    }
    // 下一张
    onNext = (index) => {
        const length = this.state.list.length
        if (index + 1 === length) {
            this.setState({
                current: 0
            })
        } else {
            this.setState({
                current: index + 1
            })
        }
    }
    render() {
        let { list, current, itemWidth, out, offset } = this.state
        // 水平式   计算轮播区整体位移
        let x = current === 0 ? 0 : - current * itemWidth + 'px'
        const translateX = `translateX(${x})`
        return (
            <div className="card-carousels">
                <div className='wrapper'>
                        <div className='inner'>
                        <div ref='swiper' className='swiper' style={{ transform: translateX }}>
                            {
                                list.map((item, key) =>
                                (
                                    <div ref='swiperitem' className={['swiper-item', key === current ? 'current' : ''].join(" ")} key={key}>
                                        <div className='pic' >
                                            <img src={item} alt="" />
                                        </div>
                                    </div>
                                )
                                )}
                        </div>
                    </div>
                   

                </div>
                    {/* {点击按扭} */}
                    <div className='btn-prev' onClick={() => this.onPrev(current)}>&lt;</div>
                    <div className='btn-next' onClick={() => this.onNext(current)}>&gt;</div>
            </div>
        )
    }
}

