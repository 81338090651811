import Banner from "../../components/index/Banner";
import Content from "../../components/index/Content";
import Content2 from "../../components/index/Content2";
import Content3 from "../../components/index/Content3";
import Content4 from "../../components/index/Content4";
// import Content5 from "../../components/Content5";
// import Box from "../../components/index/box"
// import backGround from"../../assets/back.png";
import { useEffect } from 'react'
import bannerImage from "../../assets/banner.svg"
const backImg = {
  width: "100%",
  height: '780px',
  // background: 'radial-gradient(800px at center,#C7F5FF, #DFF3FB, #C7E8F9, #B3D7F9 )',
  // backgroundImage: `url(${bannerImage})`,
  // backgroundSize:"100% 100%",
  paddingTop: '64px',
  // backgroundSize:"cover"
  backgroundColor: '#C6DEF5'
}
const PicBack = {
  width: '1440px',
  // height:'780px',
  backgroundImage: `url(${bannerImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: '100% 100%',
  margin: '0 auto'
}
const backColor = {
  width: "100%",
  backgroundColor: '#F7FCFF'
}
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ width: '100%' }}>
      <div style={backImg}>
        <div style={PicBack}>
          <Banner />
        </div>
      </div>
      <div >
      </div>
      <Content />
      <div style={backColor}>
        <Content2 />
      </div>
      <Content3 />
      <div style={backColor}>
        <Content4 />
      </div>
      {/* <Content5 /> */}
      {/* <Box></Box> */}
    </div>
  );
}

export default Home;
