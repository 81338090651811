import React, { Component } from 'react'
import './solve.scss'
export default class LandingPractice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      case1: true,
      case2: false,
      case3: false,
      case4: false,
      case5: false,
      case6: false,
      case7: false,
      activeIndex:0
    }
  }
  handleChange = (id,index, e) => {
    console.log(index, e, 52);
    this.setState({
      activeIndex:index
     })
    if (id == 1) {
      console.log(123);
      this.setState({
        case1: true,
        case2: false,
        case3: false,
        case4: false,
        case5: false,
        case6: false,
        case7: false,
      })
    }
    if (id == 2) {
      this.setState({
        case1: false,
        case2: true,
        case3: false,
        case4: false,
        case5: false,
        case6: false,
        case7: false,
      })
    }
    if (id == 3) {
      this.setState({
        case1: false,
        case2: false,
        case3: true,
        case4: false,
        case5: false,
        case6: false,
        case7: false,
      })
    }
    if (id == 4) {
      this.setState({
        case1: false,
        case2: false,
        case3: false,
        case4: true,
        case5: false,
        case6: false,
        case7: false,
      })
    }
    if (id == 5) {
      this.setState({
        case1: false,
        case2: false,
        case3: false,
        case4: false,
        case5: true,
        case6: false,
        case7: false,
      })
    }
    if (id == 6) {
      this.setState({
        case1: false,
        case2: false,
        case3: false,
        case4: false,
        case5: false,
        case6: true,
        case7: false,
      })
    }
    if (id == 7) {
      this.setState({
        case1: false,
        case2: false,
        case3: false,
        case4: false,
        case5: false,
        case6: false,
        case7: true,
      })
    }
  }
  render() {
    // console.log(this.props, 5);
    return (
      <div className='LandingPractice'>
        <p className='title'>落地实践</p>
        <div className='UseContent'>
          <div className='nameWrap'>
            {
              this.props.nameList.map((item,index) => {
                return (
                  <div className='Carousel' key={item.id}>
                    <div className={`titWrap ${this.state.activeIndex===index?'active':' '}`} onMouseEnter={(e) => this.handleChange(item.id,index, e)}>
                      <div className='tipContent'>0{item.id}</div>
                      <div className='line'></div>
                      <div className='tit'>{item.tit}</div>
                    </div>
                    {/* <div className='san' /> */}
                  </div>
                )
              })
            }
          </div>

          <div>
            {
              this.state.case1 ? (<div className='contWrap' >
                {/* <div className='san' /> */}
                <div className='leftBox'>
                  <img src={this.props.img.case1} alt="" />
                </div>
              </div>) : ''
            }
            {
              this.state.case2 ? (<div className='contWrap'>
                {/* <div className='san2' /> */}
                <div className='leftBox'>
                <img src={this.props.img.case2} alt="" />
                </div>
              </div>) : ''
            }
            {
              this.state.case3 ? (<div className='contWrap'>
                {/* <div className='san3' /> */}
                <div className='leftBox'>
                <img src={this.props.img.case3} alt="" />
                </div>
              </div>) : ''
            }
            {
              this.state.case4 ? (<div className='contWrap'>
                {/* <div className='san4' /> */}
                <div className='leftBox'>
                <img src={this.props.img.case4} alt="" />
                </div>
              </div>) : ''
            }
            {
              this.state.case5 ? (<div className='contWrap'>
                {/* <div className='san2' /> */}
                <div className='leftBox'>
                <img src={this.props.img.case5} alt="" />
                </div>
              </div>) : ''
            }
            {
              this.state.case6 ? (<div className='contWrap'>
                {/* <div className='san3' /> */}
                <div className='leftBox'>
                <img src={this.props.img.case6} alt="" />
                </div>
              </div>) : ''
            }
            {
              this.state.case7 ? (<div className='contWrap'>
                {/* <div className='san4' /> */}
                <div className='leftBox'>
                <img src={this.props.img.case7} alt="" />
                </div>
              </div>) : ''
            }
          </div>
        </div>
      </div>
    )
  }
}
