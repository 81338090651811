import React, { Component } from 'react'
import './solve.scss'


export default class SchemeAdvantages extends Component {
  render() {
    return (
      <div className='SchemeAdvantages'>
        <p className='title'>方案优势</p>
        <div className='contentWrap'>
            {
              this.props.contentList.map(item => {
                return (
                  <div className='boxWrap'>
                    <div className='leBox'>
                      <img src={item.img} alt="" />
                    </div>
                    <div className='riBox'>
                      <div className='tit'>
                        {item.tit}
                      </div>
                      <div className='tip'>
                        {item.tip}
                      </div>
                    </div>

                  </div>
                )
              })
            }
        </div>
      </div>
    )
  }
}
