import React, { Component } from 'react'
//组件
import Images from "../../assets/Solution/nengyuan.png"
import Banner from "../../components/Banner"
import CasePain from '../../components/solve/casePain'
import Introduce from "../../components/solve/SchemeArchitecture"
import SchemeArchitecture from "../../components/solve/SchemeAdvantages"
import Use from "../../components/solve/LandingPractice"

import backGround from "../../assets/bg-topS.png";
const bannerStyle={
  width: "100%",
  backgroundImage: `url(${backGround})`,
  backgroundSize: '100% 100%',
  backgroundRepeat:'no-repeat'
}
const backColor = {
  width: "100%",
  backgroundColor: '#F5F9FC'
}

export default class energy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerContent: {
        tit: '智慧能源解决方案',
        cont: '智科云鑫通过智慧能源管理平台，为公共机构、企事业单位提供综合能源运营与管理解决方案，通过能源物联、能耗计量、能耗分析、策略寻优、管理优化、设备改造等综合措施，帮助公共机构、企事业单位提升能源效率、降低能耗成本，助力低碳绿色目标达成。'
      },
      casePain: [
        {
          img: require('../../assets/Solution/1.png'),
          tit: '能源消耗黑盒子',
          cont: '3060”战略明确后，越来越多的工矿、企事业单位开始关注能源消耗和碳排放。但目前多数单位仅能获取自身能源消耗的总量，对能源使用状态无法实时、直观感知，对分时分类分设备的能耗无法准确测量，导致精准化能源管理工作无法开展。'
        },
        {
          img: require('../../assets/Solution/2.png'),
          tit: '能源管理不落地',
          cont: '46%以节能减排为目标，公共机构、企事业单位普遍制订一系列能源管理，特别是节能减排方面的规章制度。但由于缺乏精准、实时的能源计量和使用监督手段，导致能源管理仅能以抽查和事后考核方式实施，真正有助于提高能源使用效率、减少能源无效使用的管理手段无法落地。'
        },
        {
          img: require('../../assets/Solution/3.png'),
          tit: '能源设备零散管',
          cont: ' 多数设施设备，即便是智能化设备使用独立的管理平台，不能集中管控，导致管理效率低、能耗等指标采集准确度低，不能实施场景化的一体控制和策略部署。'
        },
      ],
      contentList: [
        {
          id: '01',
          tit: '快速部署和物联支持能力',
          tip: '依托智科云鑫优势智慧物联技术和物联运营经验，可为合作单位低成本快速构建能源物联平台。智科云鑫能源物联平台具有极强的扩展能力，平台支持广泛的设备、协议、接口接入，通过设备全生命周期管理模组部署，可根据客户需要扩展成为智能设备管理的主平台。',
          img:require('../../assets/aadvantage/33.png')
        },
        {
          id: '02',
          tit: '成熟丰富的能源策略模组',
          tip: '智慧能源管理平台及其前端电量计量设备，实现设备、场景、线路精准能耗计量，通过优化算法匹配策略库，系统实现设备运行策略、场景能耗策略的智能寻优，保证设备、场景运行在最优工况，降低能耗延长设备使用寿命，并可为管理优化、设备技改等提供数据支持。',
          img:require('../../assets/aadvantage/34.png')
        },
        {
          id: '03',
          tit: '综合能源服务模式',
          tip: '智科云鑫携手专业单位，为公共机构、企事业单位提供综合能源服务。综合服务模式下，智科云鑫以电费托管、设备托管、联合运营等多种方式，为客户提供包括代理电力市场化交易、碳资产交易、合作运营分布式光伏发电站、按“投资',
          img:require('../../assets/aadvantage/35.png')
        },
      ],
      nameList: [
        {
          id: '1',
          tit: '方案一',
        },
        {
          id: '2',
          tit: '方案二',
        },
        {
          id: '3',
          tit: '方案三',
        },
        {
          id: '4',
          tit: '方案四',
        },
        {
          id: '5',
          tit: '方案五',
        },
        {
          id: '6',
          tit: '方案六',
        },
        {
          id: '7',
          tit: '方案七',
        }
      ],
      img:{
        case1:require('../../assets/Solution/s1.png'),
        case2:require('../../assets/Solution/s2.png'),
        case3:require('../../assets/Solution/s3.png'),
        case4:require('../../assets/Solution/s4.png'),
        case5:require('../../assets/Solution/s5.png'),
        case6:require('../../assets/Solution/s6.png'),
        case7:require('../../assets/Solution/s7.png'),
      }
    }
  }
  componentDidMount() {
    // 返回顶部
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className='energy'>
        {/* 导航部分 */}
        <div style={bannerStyle}>
          <Banner bannerContent={this.state.bannerContent} />
        </div>
        {/* 方案痛点 */}
        <CasePain casePain={this.state.casePain} />
        {/* 业务方案 */}
        <div style={backColor}>
          <Introduce Images={Images} />
        </div>
        {/* 产品优势 */}
        <SchemeArchitecture contentList={this.state.contentList} />
        {/* 使用场景 */}
        <div style={backColor}>
          <Use nameList={this.state.nameList} img={this.state.img}/>
        </div>
      </div >
    )
  }
}
