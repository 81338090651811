import React, { Component } from 'react'
import './service.scss'

export default class ProductAdvantages extends Component {
    render() {
        return (
            <div className='ProductAdvantages'>
                <p className='title'>产品优势</p>
                <div className='contentb'>
                    {
                        this.props.tipList.map(item => {
                            return (
                                <div className='boxWrap' key={item.tit}>
                                    <div className='leBox'>
                                        <img src={item.img} alt="" />
                                    </div>
                                    <div className='riBox'>
                                        <div className='tit'>
                                            {item.tit}
                                        </div>
                                        <div className='tip'>
                                            {item.tip}
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
