import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import Logo from "../../assets/BJlaboremployment.png";
import exhibitionImg from "../../assets/BJmudan.png"
import './index.scss'
//组件
import Banner from "../../components/Banner";
import backImage from "../../assets/jingdian.jpg"
const backColor = {
  width: "100%",
  backgroundColor: '#F0F5FA',
  backgroundImage: `url(${backImage})`,
  backgroundSize: '100% 100%',
}
const bannerContent={
  tit: '经典案例',
        cont: '推动行业客户数字化转型，提升企业核心生产业务效率'
}
export default class BJlaboremployment extends Component {
  render () {
    return (
      <div className='BJlaboremployment'>
        <div style={backColor}>
        <Banner bannerContent={bannerContent} backImage={backImage}/>
        </div>
        <div className='gotoPage'>
          <Link to="/case"> 经典案例&gt;</Link>劳动用工风险监测预警平台
        </div>
        <div className='project'>
          <div className='leftWrap'>
            {/* <div className='img'>
              <img src={Logo} alt="" />
            </div> */}
            <div className='title'>
              劳动用工风险监测预警平台
            </div>
          </div>

          <div className='introduce'>
            <p className='tit'>
              劳动用工风险监测预警平台
            </p>
            <div className='tip'>
              充分利用大数据人工智能、云计算等技术实现了风险监测处置工作的数字化、全景化、一体化、智能化、专业化。综合社情、舆情、产情和企业基础信息，对企业可能发生的规模性裁减员风险进行监测预警。
            </div>
          </div>
        </div>
        <div className='describe'>
          劳动用工风险监控平台建立劳动关系监测预警的”三全、三早、三对接”的工作机制，“充分利用大数据人工智能、云计算等技术实现了风险监测处置工作的数字化、全景化、一体化、智能化、专业化。综合社情、舆情、产情和企业基础信息，对企业可能发生的规模性裁减员风险进行监测预警。本着早发现、早预警、早处置的原则，指导企业完善裁减员方案，同时切实保障裁减员过程中职工权益和再就业对接服务落实到位。
        </div>
        <div className='Exhibition'>
          <img src={exhibitionImg} alt="" />
        </div>
      </div>
    )
  }
}
