import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg'
import phone from '../assets/phone.png'
import './public.scss'

// const AboutList=[
//     {
//         id:1,
//         title:'最新动态',
//         content:[
//             {
//                 name:'公司动态'
//             },
//             {
//                 name:'媒体报道'
//             },
//             {
//                 name:'产品更新'
//             },
//             {
//                 name:'技术社区'
//             }
//         ]
//     },
//     {id:2,
//         title:'联系我们',
//         content:[
//             {
//                 name:'联系我们'
//             }
//         ]
//     },
//     {
//         id:3,
//         title:'加入我们',
//         content:[
//             {
//                 name:'最新招聘'
//             }
//         ]
//     },

// ]
class Top extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ServiceShow: false,
            SolveShow: false,
            CaseShow: false,
            AboutShow: false,
            ContactShow: false,
            black: true//导航栏背景
        }
    }
    ServiceEnter = () => {
        console.log('触发了事件')
        this.setState({
            ServiceShow: !this.state.ServiceShow,
        })
    }
    SolveEnter = () => {
        console.log('触发了事件')
        this.setState({
            SolveShow: !this.state.SolveShow,
        })
    }
    CaseEnter = () => {
        this.setState({
            CaseShow: true,
        })
    }
    AboutEnter = () => {
        this.setState({
            AboutShow: true,
        })
    }
    ContactEnter = () => {
        this.setState({
            ContactShow: !this.state.ContactShow,
        })
    }
    ServiceLeve = () => {
        console.log('触发了事件')
        this.setState({
            ServiceShow: !this.state.ServiceShow,
        })
    }
    SolveLeve = () => {
        console.log('触发了事件')
        this.setState({
            SolveShow: !this.state.SolveShow,
        })
    }
    CaseLeve = () => {
        this.setState({
            CaseShow: false,
        })
    }
    AboutLeve = () => {
        this.setState({
            AboutShow: false,
        })
    }
    ContactLeve = () => {
        this.setState({
            ContactShow: false,
        })
    }
    //在componentDidMount，进行scroll事件的注册，绑定一个函数，让这个函数进行监听处理
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
   
    handleScroll = (event) => {
        //滚动条高度
        // let clientHeight = document.documentElement.clientHeight; //可视区域高度
        let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
        // let scrollHeight =document.documentElement.scrollHeight; //滚动内容高度
        if (scrollTop > 80) {
            // console.log(scrollTop,439)
            // console.log(event,439)
            this.setState({ black: false })
        } else {
            this.setState({ black: true })
        }
    }
    //在componentWillUnmount，进行scroll事件的注销
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }

    render() {
        let Box_background = this.state.black ? 'rgba(255,255,255,0.28)' : '#fff';
        return (
            // className={Box_background}
            <div className='Box' style={{ background: Box_background }}>
                <div className='headStyle2'>
                    <div className='leftBox2'>
                        <div style={{ marginRight: '30px' ,paddingTop:'12px'}} >
                            <Link to="/home">
                                <img src={logo} className='ImgStyle' alt="logo" />
                            </Link>
                        </div>
                        <div className='lineStyle' >
                            <div className='liStyle' onMouseEnter={this.ServiceEnter} onMouseLeave={this.ServiceLeve}>
                                <div to='/service' className='linkStyle' >产品服务</div>
                                {
                                    this.state.ServiceShow ? (<div className='line'></div>) : ''
                                }
                                {
                                    this.state.ServiceShow ? (<div className='downBox'>
                                        <ul className='ulStyle'>
                                            <li className='liBox'>
                                                <p className='title'>数字政务应用</p>
                                                <div className='lineBox'></div>
                                                <Link to="/employmentRisk">
                                                    <div  className='nameWrap'>
                                                        <p className='tname'>用工风险监测预警平台</p>
                                                        <p className='tip'> 建立劳动关系风险监测预警制度</p>
                                                    </div>
                                                </Link>
                                                <Link to="/CollaborativeGovernance">
                                                    <div  className='nameWrap'>
                                                        <p className='tname'>社区协同治理平台</p>
                                                        <p className='tip'> 建立多社联动的社区治理与运营新生态</p>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className='liBox'>
                                                <p className='title'>数字校园应用</p>
                                                <div className='lineBox'></div>
                                                <Link to="/securityManagement">
                                                    <div  className='nameWrap'>
                                                        <p className='tname'>数字校园安全管理平台</p>
                                                        <p className='tip'> 为师生提供一站式管理服务</p>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className='liBox'>
                                                <p className='title'>数字化支撑平台</p>
                                                <div className='lineBox'></div>
                                                <Link to="/iotPerception">
                                                    <div  className='nameWrap'>
                                                        <p className='tname'>智慧物联感知平台</p>
                                                        <p className='tip'> 以应用为中心的企业级容器云</p>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>) : ''
                                }

                            </div>
                            <div className='liStyle' onMouseEnter={this.SolveEnter} onMouseLeave={this.SolveLeve}>
                                <div to='/solve' className='linkStyle' >解决方案</div>
                                {
                                    this.state.SolveShow ? (<div className='line'></div>) : ''
                                }
                                 
                                {
                                    this.state.SolveShow ? (<div className='downBox2'>
                                        <ul className='ulStyle'>
                                            <li className='liBox'>
                                                {/* <div className='lineBox2'></div> */}
                                                <Link to="/internet">
                                                    <div  className='nameWrap'>
                                                        <p className='tname'>工业互联网解决方案</p>
                                                    </div>
                                                </Link>
                                                {/* <Link to="/campus">
                                                    <div  className='nameWrap'>
                                                        <p className='tname'>数字化校园解决方案</p>
                                                    </div>
                                                </Link> */}
                                                <Link to="/governmentAffairs">
                                                    <div  className='nameWrap'>
                                                        <p className='tname'>数字化管控解决方案</p>
                                                    </div>
                                                </Link>
                                                <Link to="/energy">
                                                    <div  className='nameWrap'>
                                                        <p className='tname'>智慧能源解决方案</p>
                                                    </div>
                                                </Link>
                                                <Link to="/community">
                                                    <div  className='nameWrap' style={{marginBottom:'0px'}}>
                                                        <p className='tname'>智慧社区解决方案</p>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>) : ''
                                }

                            </div>
                            <div className='liStyle' onMouseEnter={this.CaseEnter} onMouseLeave={this.CaseLeve}>
                                <Link to='/case' className='linkStyle' >经典案例</Link>
                                {
                                    this.state.CaseShow ? (<div className='line'></div>) : ''
                                }
                            </div>
                            <div className='liStyle' onMouseEnter={this.AboutEnter} onMouseLeave={this.AboutLeve}>
                                <Link to='/about' className='linkStyle' >企业概况</Link>
                                {
                                    this.state.AboutShow ? (<div className='line'></div>) : ''
                                }
                                {/* {
                                   this.state.AboutShow?( <div style={downBox}>
                                    <ul style={ulStyle2}>
                                        <li style={liBox2}>
                                            <div style={{ marginBottom: '40px' }}>
                                                <img src={logo} style={downImg} alt="logo"></img>
                                            </div>
                                            <p style={font}>
                                                了解智科云鑫 &gt;
                                            </p>
                                            <span style={spanStyle}>
                                                智科云鑫(XX)科技有限公司
                                                经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；软件开发；人工智能基础软件开发；人工智能应用软件开发；网络与信息安全软件开发；人工智能理论与算法软件开发...
                                            </span>
                                        </li>
                                        {
                                            AboutList.map((item) => {
                                                return (
                                                    <li key={item.id} style={liBox2}>
                                                        <p style={title}>{item.title}</p>
                                                        <div style={lineBox}></div>
                                                        {item.content.map((cot, index) => {
                                                            return (
                                                                <div key={cot.name} style={{ marginBottom: '24px' }}>
                                                                    <p style={tname}>{cot.name}</p>
                                                                </div>
                                                            )
                                                        })}
                                                    </li>
                                                )
                                            })
                                        }
            
                                    </ul>
                                </div>) :''
                                } */}
                            </div>
                            <div className='liStyle' onMouseEnter={this.ContactEnter} onMouseLeave={this.ContactLeve}>
                                <Link to='/contact' className='linkStyle' >联系我们</Link>
                                {
                                    this.state.ContactShow ? (<div className='line'></div>) : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className='rightBox'>
                        <div style={{ marginRight: '16px' }}>
                            <img src={phone} className='PhoneStyle' alt="phone" />
                        </div>
                        <span className='fontStyle'>
                            联系电话: 028-65732103
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Top;
