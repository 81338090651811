import { Link } from 'react-router-dom'
import { useEffect } from 'react'

import './index.scss'

//组件
import Banner from "../../components/Banner";
import backImage from "../../assets/jingdian.jpg"
const backColor = {
  width: "100%",
  backgroundImage: `url(${backImage})`,
  backgroundSize: '100% 100%',
}
const bannerContent = {
  tit: '经典案例',
  cont: '推动行业客户数字化转型，提升企业核心生产业务效率'
}
const Solve = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ width: '100%', background: '#F9FAFC' }}>
      <div style={backColor} >
        <Banner bannerContent={bannerContent} backImage={backImage} />
      </div>
      <div className="content">
        <Link to="/BJlaboremployment">
          <div className="smallWrap">
            <div className="titleWrap">
              劳动用户风险监测预警平台
            </div>
            <div className="contentWrap">
              劳动用工风险监控平台建立劳动关系监测预警的”三全、三早、三对接”的工作机制，“充分利用大数据人工智能、云计算等技术实现了风险监测处置工作的数字化、全景化、一体化、智能化、专业化。…
            </div>
            <div className="btnWrap">
              案例详情 &gt;&gt;
            </div>
          </div>
        </Link>
        <Link to="/BJstudent">
          <div className="smallWrap">
            <div className="titleWrap">
              学生综合服务平台
            </div>
            <div className="contentWrap">
              学生综合服务平台主要围绕学校管理、个性化服务、校园安全、绿色低碳等场景建设，通过物联设备监测控制、学校管理系统业务数据整合，实现学校数据互联互通，协同应用，XX学生综合服务平台包含了学校管理、平安校园、…
            </div>
            <div className="btnWrap">
              案例详情 &gt;&gt;
            </div>
          </div>
        </Link>
        <Link to="/DYsmartCommunity">
          <div className="smallWrap">
            <div className="titleWrap">
              安全绿色智慧小区综合服务平台
            </div>
            <div className="contentWrap">
              安全绿色智慧小区综合服务平台以老旧小区改造为抓手，融合多项社区相关政策要求，通过建设老旧小区智慧消防、非机动车充电、机动车充电系统提升服务、智慧垃圾分类提升服务、安全绿色智慧小区综合服务平台…
            </div>
            <div className="btnWrap">
              案例详情 &gt;&gt;
            </div>
          </div>
        </Link>
      </div>

    </div>
  );
}

export default Solve;
