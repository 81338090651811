import React, { Component } from 'react'
import './service.scss'
export default class ProductCharacteristics extends Component {
  render() {
    console.log(this.props.Images, 6);
    return (
      <div className='ProductCharacteristics'>
        <p className='title'>功能特性</p>
        <div className='introduce'>
          <div className='box'>
            {/* <h1>{item.id}</h1> */}
            <div className='tit'>{ this.props.contentList[0].tit}</div>
            <div className='tip'>
              { this.props.contentList[0].tip}
            </div>
          </div>
          <div className='box1'>
            {/* <h1>{item.id}</h1> */}
            <div className='tit'>{ this.props.contentList[0].tit}</div>
            <div className='tip'>
              { this.props.contentList[0].tip}
            </div>
          </div>
          <div className='box2'>
            {/* <h1>{item.id}</h1> */}
            <div className='tit'>{ this.props.contentList[0].tit}</div>
            <div className='tip'>
              { this.props.contentList[0].tip}
            </div>
          </div>
          <div className='box3'>
            {/* <h1>{item.id}</h1> */}
            <div className='tit'>{ this.props.contentList[0].tit}</div>
            <div className='tip'>
              { this.props.contentList[0].tip}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
