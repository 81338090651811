import React, { Component } from 'react'
import './service.scss'

export default class ProductArchitecture extends Component {
  render() {
    // console.log(this.props.Images);
    return (
      <div className='ProductArchitecture'>
        <p className='title'>产品构成</p>
        <div className='ProductArchitecturecontent'>
          <img src={this.props.Images} alt="" />
        </div>
      </div>
    )
  }
}
