// 产品介绍
import React, { Component } from 'react';
import backImage from "../../assets/num1.png"
import backImage2 from "../../assets/num2.png"

import './index.scss'
class Content extends Component {
    render() {
        return (
            <div className='ContentStyles'>
                <p className='p'>产品介绍</p>
                <div className='topBox'>
                    <div className='img'>
                        <img src={backImage} alt="" className='backImage'/>
                    </div>
                    <div className='font'>
                        <p className='title'>数字化支撑平台</p>
                        <span className='span'>
                        智科云鑫以数字化做为所有产品支撑的技术底座，按照云原生的规则，领域驱动设计的思维方式构建基础技术支撑平台。支撑平台重点包括以容器为主导部署编排引擎，构建系统运行基础环境；采用云边协同模式，通过研发多协议的物联支撑平台构建智能化物联场景支撑平台；采用领域聚合的方式，通过对多类型实践项目的提取抽象出系统通用工具组件，例如工单模型，申请审批流程，场景化配置引擎等实用工具；采用多元化页面呈现方式，做到按需呈现，支撑用户及项目的数据可视化和管理可视化需求。
                        </span>
                        <p className='title2'>相关产品</p>
                        <div className='btn'>
                            <div className='sWrap'>智慧物联感知平台</div>
                             {/* <div className='sWrap'>XX学生</div>
                            <div className='sWrap'>牡丹用工风险</div>  */}
                        </div>
                    </div>
                </div>
                <div className='topBox2'>
                    <div className='font'>
                        <p className='title'>数字化应用</p>
                        <span className='span'>
                        智科云鑫数字化应用，主要通过自研发的数字化支撑平台为底座，面向多种不同类型的场景，构建数字化应用，致力于解决用户痛难点问题，主要场景应用包括智慧物联，智能能源，智慧社区，数字化政务，数字化校园等。通过应用数字化建设，辅助客户做好基础数据积累，数据动态配置展示，加快企业的数字化进程。
                        </span>
                        <p className='title2'>相关产品</p>
                        <div className='btn'>
                            <div className='sWrap'>数字化政务监管服务应用</div>
                            <div className='sWrap'>数字化项目管理应用</div>
                            <div className='sWrap'>数字化学生服务应用</div>
                            <div className='sWrap'>社区协同治理平台</div>
                        </div>
                    </div>
                    <div className='img'>
                    <img src={backImage2} alt="" className='backImage'/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;
