// 用户案例
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Solve from '../../assets/solve.png'
import './index.scss'

class Content extends Component {
    render () {
        return (
            <div className='ContentStyles4'>
                <p className='p'>用户案例</p>
                <div className='tBox'>
                    <div className='lBox'>
                        <p className='pBox'>智慧社区</p>
                        <span className='spanBox'>
                            社区协同治理平台，是面向社区综合治理、社区服务运营开发的综合性服务平台。以服务社区党组织、社区居委会、社区工作者为目标，通过向包括居民、居民组织、社区商家及物业机构提供服务和运营赋能，建立多社联动的社区治理与运营新生态。
                        </span>
                        <Link to="/community">
                            <div className='btn'>
                                了解详情&gt;
                            </div>
                        </Link>
                    </div>
                    <div className='rBox'>
                        <img src={Solve} alt="" className='img' />
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;
