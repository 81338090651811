// import Banner  from "../../components/solve/Banner";
import ProductArchitecture from "../../components/solve/SchemeArchitecture"
// import ProgramAdvantages from "../../components/solve/SchemeAdvantages"
import backGround from"../../assets/case.png";
import { useEffect  } from 'react'
const backImg={
  width: "100%",
  // height:'100%',
  backgroundImage: `url(${backGround})`,
  backgroundSize: '100% 100%',
  paddingTop:'64px'
}
const backColor={
  width: "100%",
  backgroundColor:'#F5F9FC'
}
const Solve =()=> {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
    return (
      <div style={{width:'100%'}}>
       <div style={{width:'100%'}}>
      <div style={backImg}>
        {/* <Banner /> */}
      </div>
      {/* <ProductArchitecture /> */}
      <div style={backColor}>
        {/* <ProgramAdvantages /> */}
      </div>
      
    </div>
      </div>
    );
  }
  
  export default Solve;
  