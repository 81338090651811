import React, { Component } from 'react'
//组件
import Images from "../../assets/Solution/webS.png"
import Banner from "../../components/Banner"
import CasePain from '../../components/solve/casePain'
import Introduce from "../../components/solve/SchemeArchitecture"
import SchemeArchitecture from"../../components/solve/SchemeAdvantages"
import Use from "../../components/solve/LandingPractice"

import backGround from "../../assets/bg-topS.png";
const bannerStyle={
  width: "100%",
  backgroundImage: `url(${backGround})`,
  backgroundSize: '100% 100%',
  backgroundRepeat:'no-repeat'
}

const backColor = {
  width: "100%",
  backgroundColor: '#F5F9FC'
}
export default class internet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerContent: {
        tit: '工业互联网门户解决方案',
        cont: '帮助大型、多制造模式、多工厂的集团型制造业管控安全、提高质量、缩短交期、提升效率、降低成本，由此提升综合竞争力，实现数智化转型。'
      },
      //痛点
      casePain: [
        {
          img: require('../../assets/Solution/1.png'),
          tit: '跨系统协作',
          cont: '改造实施区域54%分散/独立建设的各子项目让各个领域分散在不同的系统中，缺乏统一化，全局化的服务视图与交付门户'
        },
        {
          img: require('../../assets/Solution/2.png'),
          tit: '创新能力不足',
          cont: '缺乏统一的工业数据与工业（微）服务集成规范/工具，限制了基于工业数据/工业服务集成创新能力'
        },
        {
          img: require('../../assets/Solution/3.png'),
          tit: '工业知识积累共享困难',
          cont: '工业知识与机理库的共享机制缺乏，需要通过一下可搜索的工业文档与资料服务，实现知识共享-2位人员负责小区值守的现状下，后期运营侧移交和实施中，存在主体缺失的问题'
        },
        {
          img: require('../../assets/Solution/4.png'),
          tit: '推广成本高',
          cont: '工业APP集中化管控和快速交付能力较弱，限制了在工厂/行业的快速推广-2位人员负责小区值守的现状下，后期运营侧移交和实施中，存在主体缺失的问题'
        },
      ],
      //优势
      contentList: [
        {
          id: '01',
          tit: '智能化生产',
          tip: '实现单个机器到产线、车间乃至整个工厂的智能决策和动态优化，提升全流程生产效率，降低成本。',
          img:require('../../assets/aadvantage/18.png')
        },
        {
          id: '02',
          tit: '网络协同',
          tip: '支持众包众创、协同设计、协同制造、垂直电商等新模式，大幅缩短产品上市周期。',
          img:require('../../assets/aadvantage/19.png')
        },
        {
          id: '03',
          tit: '个性定制',
          tip: '智科云鑫通过灵活柔性组织设计、制造资源和生产流程、实现低成本大规模定制。',
          img:require('../../assets/aadvantage/20.png')
        },
        {
          id: '04',
          tit: '服务延伸',
          tip: '通过对产品运行的实时监测、数据采集、支持远程维护、故障预测、性能优化、数据分析等一系列延伸服务。',
          img:require('../../assets/aadvantage/21.png')
        }
      ],
      //使用场景
      nameList: [
        {
          id: '1',
          tit: '我的审批',
        },
        {
          id: '2',
          tit: '我的账单',
        },
        {
          id: '3',
          tit: '应用商店',
        },
        {
          id: '4',
          tit: '添加云资源',
        },
        {
          id: '5',
          tit: '服务运营',
        },
        {
          id: '6',
          tit: '互联网服务',
        },
      ],
      img:{
        case1:require('../../assets/Solution/shenpi.png'),
        case2:require('../../assets/Solution/zhangdan.png'),
        case3:require('../../assets/Solution/shangdian.png'),
        case4:require('../../assets/Solution/addziyuan.png'),
        case5:require('../../assets/Solution/control.png'),
        case6:require('../../assets/Solution/web.png'),
      }
    }
  }
  componentDidMount() {
    // 返回顶部
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className='internet'>
       {/* 导航部分 */}
       <div style={bannerStyle}>
          <Banner bannerContent={this.state.bannerContent} />
        </div>
        {/* 方案痛点 */}
        <CasePain casePain={this.state.casePain} />
        {/* 业务方案 */}
        <div style={backColor}>
          <Introduce Images={Images} />
        </div>
        {/* 产品优势 */}
        <SchemeArchitecture contentList={this.state.contentList} />
         {/* 使用场景 */}
         <div style={backColor}>
          <Use nameList={this.state.nameList} img={this.state.img}/>
        </div>
    </div >
    )
  }
}
