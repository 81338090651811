import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import player from '../../assets/control/1.mp4'
//组件
import Images from "../../assets/Solution/guankongjiejue.png"
import Banner from "../../components/Banner"
import CasePain from '../../components/solve/casePain'
import Introduce from "../../components/solve/SchemeArchitecture"
import SchemeArchitecture from"../../components/solve/SchemeAdvantages"
import Use from "../../components/solve/UsageScenarios2"

import backGround from "../../assets/bg-topS.png";
const bannerStyle={
  width: "100%",
  backgroundImage: `url(${backGround})`,
  backgroundSize: '100% 100%',
  backgroundRepeat:'no-repeat'
}
const backColor = {
  width: "100%",
  backgroundColor: '#F5F9FC'
}

export default class governmentAffairs extends Component {

  constructor(props) {
    super(props)
    this.state = {
      bannerContent: {
        tit: '数字化综合管控解决方案',
        cont: '通过先进的数据服务与应用技术发现数据价值，提高数据资产化水平，形成自主知识产权，推动企业智能制造步伐、助力国企数字化转型。'
      },
      casePain: [
        {
          img: require('../../assets/Solution/1.png'),
          tit: '监管难度大',
          cont: '由于企业体系庞大负责，自顶向下的级联制度导致单位数量多且情况负责，给监管工作带来一定的工作量，监管难度较大'
        },
        {
          img: require('../../assets/Solution/2.png'),
          tit: '信息不对称',
          cont: '企业管控中，多维信息传递时，由于层级关系，引起信息传递失真，给管理上增加不确定性。'
        },
        {
          img: require('../../assets/Solution/3.png'),
          tit: '治理结构不完善',
          cont: '企业治理涉及到党建，人才，财务，事项，考核等多项工作职能的协同治理，目前按职能划分导致数据流通不畅，对于治理结构需要进一步完善-2位人员负责小区值守的现状下，后期运营侧移交和实施中，存在主体缺失的问题'
        },
        {
          img: require('../../assets/Solution/4.png'),
          tit: '数字化手段缺失',
          cont: '企业在管控和治理过程中，缺乏有效的数字化管控手段，在信息留存，整体情况分析上面需要进一步完善。'
        }
      ],
      contentList: [
        {
          id:1,
          tit: '基础提升      ',
          tip: '国企数字化管控需在一定知识积累与项目实践经验基础上进行设计与建设，避免“一张白纸”的项目建设初始阶段，同时实现项目过程中对现有参考基础的个性化完善实现与提升。      ',
          img:require('../../assets/aadvantage/27.png')
        },
        {
          id:2,
          tit: '提供能力      ',
          tip: ' 统一提供高性能的数据资产管理与服务、管理应用与业务分析开发能力，在确保现阶段项目快速高标准建设的同时，为电控未来信息化建设提供有效的技术、管理标准支持。      ',
          img:require('../../assets/aadvantage/28.png')
        },
        {
          id:3,
          tit: '快速见效      ',
          tip: '企业数字化建设是一项较为长期的任务，建设阶段中为了时刻保持项目建设方向的准确性以及能够伴随项目建设过程随时为业务提供所需支持，因此项目过程中需要着重考虑快速见效的原则。',
          img:require('../../assets/aadvantage/29.png')
        },
        {
          id:4,
          tit: '固化共性 ',
          tip: '将业务中可最大程度适用于类似集团性企业的管理制度、业务管理方式、管理主题以及管理中重点、常用指标的沉淀与固化，为平台建立面向多用户服务的基础业务能力。    ',
          img:require('../../assets/aadvantage/30.png')
        },
        {
          id:5,
          tit: '统一平台  ',
          tip: '将国企信息化建设与管理、服务的能力集中于本次项目所涵盖数据中台与业务中台 ，通过一个平台的建设，实现集团信息化建设标准的一致性与管理的一体化。    ',
          img:require('../../assets/aadvantage/31.png')
        },
        {
          id:6,
          tit: '赋能转移  ',
          tip: '针对平台建设成果、建设思路、建设过程经验以及相关业务管理体系等知识通过项目规划与建设过程，逐步实现向国企数据中心的转移与赋能。  ',
          img:require('../../assets/aadvantage/32.png')
        }
      ],
       //使用场景
       nameList: [
        {
          id: 1,
          name: '数据可视化服务 ',
          content: '针对国企管控过程中产生的数据，通过对多维度的数据进行汇总，过滤，处理。按照多种图标的方式，呈现业务走势以及管控情况。          ',
          img:require('../../assets/Solution/shuju.png'),
        },
        {
          id: 2,
          name: '事项管控',
          content: '事项管理子系统完成不同类型事项创建、配置、下发、填报、审核等管理流程，并且形成对管理流程跟踪。      ',
          img:require('../../assets/Solution/shixiang.png')
        },
        {
          id: 3,
          name: '业务管控',
          content: '管控业务子系统已经完成的功能包括对于三重一大、会议管理子平台开发建设。          ',
          img:require('../../assets/Solution/yewu.png')
        },
        {
          id: 4,
          name: '运管管理',
          content: '运管管理子系统主要实现的功能包括系统权限配置，角色管理，用户管理等模块。          ',
          img:require('../../assets/Solution/yunguan.png')
        }
      ]
    }
  }
  componentDidMount() {
    // 返回顶部
    window.scrollTo(0, 0);
  }
  handleChange = (e) => {
    // console.log(,52);
    if (e.target.innerText === '数据中台') {
      console.log('数据中台')
      this.setState({
        collection: true,
        control: false,
        follow: false,
        service: false
      })
    }
    if (e.target.innerText === '事项管控') {
      console.log('事项管控')
      this.setState({
        collection: false,
        control: true,
        follow: false,
        service: false
      })
    }
    if (e.target.innerText === '业务管控') {
      console.log('业务管控')
      this.setState({
        collection: false,
        control: false,
        follow: true,
        service: false
      })
    }
    if (e.target.innerText === '运管管理') {
      console.log('运管管理')
      this.setState({
        collection: false,
        control: false,
        follow: false,
        service: true
      })
    }
  }
  render () {
    return (
      <div className='governmentAffairs'>
        {/* 导航部分 */}
        <div style={bannerStyle}>
          <Banner bannerContent={this.state.bannerContent} />
        </div>
        {/* 方案痛点 */}
        <CasePain casePain={this.state.casePain} />
        {/* 业务方案 */}
        <div style={backColor}>
          <Introduce Images={Images} />
        </div>
        {/* 产品优势 */}
        <SchemeArchitecture contentList={this.state.contentList} />
        <div style={backColor}>
           {/* 使用场景 */}
        <div style={backColor}>
          <Use nameList={this.state.nameList} />
        </div>
        </div>
        <div className='UsageScenarios'>
          <p className='title'>实现效果</p>
          <div className='contenta'>
            {/* <img src={li} alt="" /> */}
            <ReactPlayer url={player} className="vidio"  playing={true}/>
          </div>
        </div>
      </div >
    )
  }
}
