import React, { Component } from 'react'
import './solve.scss'
export default class casePain extends Component {
  render() {
    return (
      <div className='casePain'>
        <div className='title'>业务痛点</div>
        <div className='contentBox'>
          {
            this.props.casePain.map((item) => {
              return (
                <div className={(this.props.casePain.length===3?'box':'box2')} key={item.tit}>
                  <div className='imgBox'>
                    <img src={item.img} alt="" />
                  </div>
                  <div className='tit'>
                    {item.tit}
                  </div>
                  <div className='cont'>
                    {item.cont}
                  </div>
                </div>
              )
            })
          }
        </div>



      </div>
    )
  }
}
