import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import Logo from "../../assets/DYlogo.png";
import exhibitionImg from "../../assets/DYcommunity.png"
import './index.scss'
//组件
import Banner from "../../components/Banner";
import backImage from "../../assets/jingdian.jpg"
const backColor = {
  width: "100%",
  backgroundImage: `url(${backImage})`,
  backgroundSize: '100% 100%',
}
const bannerContent={
  tit: '经典案例',
        cont: '推动行业客户数字化转型，提升企业核心生产业务效率'
}
export default class DYsmartCommunity extends Component {
  render() {
    return (
      <div className='BJlaboremployment'>
       <div style={backColor}>
        <Banner bannerContent={bannerContent} backImage={backImage}/>
        </div>
        <div className='gotoPage'>
          <Link to="/case"> 经典案例&gt;</Link> 安全绿色智慧小区综合服务平台
        </div>
        <div className='project'>
          <div className='leftWrap'>
            {/* <div className='img'>
              <img src={Logo} alt="" />
            </div> */}
            <div className='title'>
              智慧小区综合服务平台
            </div>
          </div>

          <div className='introduce'>
            <p className='tit'>
              安全绿色智慧小区综合服务平台
            </p>
            <div className='tip'>
              通过建设老旧小区智慧消防、非机动车充电、机动车充电系统提升服务、智慧垃圾分类提升服务、安全绿色智慧小区综合服务平台，整合分散的各类硬件设备实现“整体社区”。
            </div>
          </div>
        </div>
        <div className='describe'>
          安全绿色智慧小区综合服务平台以老旧小区改造为抓手，融合多项社区相关政策要求，通过建设老旧小区智慧消防、非机动车充电、机动车充电系统提升服务、智慧垃圾分类提升服务、安全绿色智慧小区综合服务平台，整合分散的各类硬件设备实现“整体社区”，赋能物业服务企业推动建立长效管理机制，最终实现消除隐患、补齐功能、加强管理、改善环境，切实提升居民的安全感、获得感、幸福感。
        </div>
        <div className='Exhibition'>
          <img src={exhibitionImg} alt="" />
        </div>
      </div>
    )
  }
}
