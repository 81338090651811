import React, { Component } from 'react'
import Images from "../../assets/ProductService/wulian.png"
import Banner from "../../components/Banner"
import Introduce from "../../components/service/ProductArchitecture"
import Characteristics from "../../components/service/ProductCharacteristics"
import Advantages from "../../components/service/ProductAdvantages"
import Use from "../../components/service/UsageScenarios"
import backGround from "../../assets/bg-topC.png";
const backColor = {
  width: "100%",
  backgroundColor: '#F0F5FA'
}
const bannerStyle={
  width: "100%",
  backgroundImage: `url(${backGround})`,
  backgroundSize: '100% 100%',
  backgroundRepeat:'no-repeat'
}
export default class iotPerception extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerContent: {
        tit: '智慧物联感知平台',
        cont: '通过降低设备接入门槛，提供强大的应用快速搭建开发工具，为海量的智能设备设备，智慧场景提供安全可靠的连接能力。为智慧城市、智慧社区、智慧园区、智慧能源等应用场景赋能。让不同的用户都能轻松便捷的体验物联网应用。'
      },
      //功能特性
      contentList: [
        {
          id: '01',
          tit: '便捷高效的设备集成能力',
          tip: '支持多种标准接入协议、多种网关，满足不同网络类型、不同协议、不同功耗的设备接入提供百万级别并发、亿级别设备连接。'
        },
        {
          id: '02',
          tit: '安全可用',
          tip: '通过高可用、高扩展、安全加密等手段保障庞大的设备接入数量大幅提升系统运行性能，满足不同场景，不同业务的增长需求，保障数据隐私安全。'
        },
        {
          id: '03',
          tit: '快速构建个性化物联网应用',
          tip: '提供物联网场景创建成熟的、开箱即用的运营服务组件，基于运营服务组件，快速组合，自选裁剪，快速构建个性化物联网应用。'
        },
        {
          id: '04',
          tit: '成本可控',
          tip: '同等条件下成本更低，应用更全面，满足不同使用需求，硬件部署快速灵活，维护程度低，支持远程维护与调试。'
        }
      ],
      //产品优势
      tipList: [
        {
          tit: '便捷高效的设备集成能力',
          tip: '支持多种标准接入协议、多种网关，满足不同网络类型、不同协议、不同功耗的设备接入提供百万级别并发、亿级别设备连接。',
          img: require('../../assets/aadvantage/14.png')
        },
        {
          tit: '安全可用      ',
          tip: '通过高可用、高扩展、安全加密等手段保障庞大的设备接入数量大幅提升系统运行性能，满足不同场景，不同业务的增长需求，保障数据隐私安全。     ',
          img: require('../../assets/aadvantage/15.png')
        },
        {
          tit: '快速构建个性化物联网应用',
          tip: '提供物联网场景创建成熟的、开箱即用的运营服务组件，基于运营服务组件，快速组合，自选裁剪，快速构建个性化物联网应用。 。     ',
          img: require('../../assets/aadvantage/16.png')
        },
        {
          tit: '成本可控',
          tip: '同等条件下成本更低，应用更全面，满足不同使用需求，硬件部署快速灵活，维护程度低，支持远程维护与调试。 ',
          img: require('../../assets/aadvantage/17.png')
        }
      ],
      //使用场景
      nameList: [
        {
          id: 1,
          name: '智慧交通',
          content: ' 通过地感线圈，摄像头，测速仪，浮动车（出租车和公交车）的GPS等各种设备可以收集到大量能够反映交通运营状况的数据，通过对这些数据进行分析，可以计算出道路的速度，流量和市民出行规律，基于计算出来的道路的通行状况和规律可以用于出行规划，交通设建设规划，交通管理优化以及其他商业目的（如连锁店分店建设选址）。将数据通过分析实现商业和公益价值。',
          img:require('../../assets/ProductService/1Scenarios.jpg')
        },
        {
          id: 2,
          name: '智慧能源',
          content: '  实现水、电、气、热的数据采集、远传、存储、预处理、统计分析等功能，依赖一站式能耗管理平台，帮助用户实现用能分析、用能概览、能效诊断、告警管理、辅助决策，为企业能源规划、能源管理和经济运行管理提供整体解决方案。',
          img:require('../../assets/ProductService/6Scenarios.jpg')
        },
        {
          id: 3,
          name: '智慧环卫',
          content: ' 实现环卫道路保洁、垃圾收运、垃圾处置、垃圾分类、公厕管理等多业务的智能监管、高效率质量督查、精细化层级考核、可视化数据分析研判，形成对环卫作业全流程的精细化、智能化、可视化及自动化管理。',
          img:require('../../assets/ProductService/5Scenarios.jpg')
        },
        {
          id: 4,
          name: '智慧物业',
          content: ' 为业主提供门禁、停车、缴费、生活服务、社圈等各项服务，为业主提供一站式服务新体验，让物业服务无忧。',
          img:require('../../assets/ProductService/4Scenarios.jpg')
        }
      ],

    }
  }
  componentDidMount() {
    // 返回顶部
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='iotPerception'>
        {/* 导航部分 */}
        <div style={bannerStyle}>
          <Banner bannerContent={this.state.bannerContent} />
        </div>
        {/* 产品介绍 */}
        <Introduce Images={Images} />
        {/* 功能特性 */}
        <div style={backColor}>
          <Characteristics contentList={this.state.contentList} />
        </div>
        {/* 产品优势 */}
        <Advantages tipList={this.state.tipList} />
        {/* 使用场景 */}
        <div style={backColor}>
          <Use nameList={this.state.nameList} />
        </div>
      </div>
    )
  }
}
