import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./public.scss"
const contactList = [
    {
        name: '公司邮箱',
        content: 'support@iyunxin.cn'
    },
    {
        name: '公司电话',
        content: '028-65732103'
    },
    {
        name: '公司地址',
        content: '中国（四川）自由贸易试验区成都高新区萃华路89号A座5 楼503号'
    }
]
class Footer extends Component {
    render() {
        return (
            <div className="footerBox" >
                <div className="headStyle">
                    <div className="topBox">
                        <div>
                            <p className="p"> 产品服务</p>
                            <div className="tbox">
                                <div className="liBox">
                                    <p className="title">数字政务应用</p>
                                    <div className="lineBox"></div>
                                    <Link to="/employmentRisk">
                                        <div >
                                            <p className="tname">用工风险监测预警平台</p>
                                        </div>
                                    </Link>
                                    <Link to="/CollaborativeGovernance">
                                        <div  >
                                            <p className="tname">社区协同治理平台</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="liBox">
                                    <p className="title">数字校园应用</p>
                                    <div className="lineBox"></div>
                                    <Link to="/securityManagement">
                                        <div  >
                                            <p className="tname">数字校园安全管理平台</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="liBox">
                                    <p className="title">数字化支撑平台</p>
                                    <div className="lineBox"></div>
                                    <Link to="/iotPerception">
                                        <div  >
                                            <p className="tname">智慧物联感知平台</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="p"> 解决方案</p>
                            <div className="solvebox">
                                {/* {
                                    solveList.map((item) => {
                                        return (
                                            <div key={item.name} className="liBox2}>
                                                <p className="title2}>{item.name}</p>
                                            </div>
                                        )
                                    })
                                } */}
                                <Link to="/internet">
                                    <div className="liBox2">
                                        <p className="title2">工业互联网解决方案</p>
                                    </div>
                                </Link>
                                {/* <Link to="/campus">
                                    <div className="liBox2">
                                        <p className="title2">数字化校园解决方案</p>
                                    </div>
                                </Link> */}
                                <Link to="/governmentAffairs">
                                    <div className="liBox2">
                                        <p className="title2">数字化政务解决方案</p>
                                    </div>
                                </Link>
                                <Link to="/energy">
                                    <div className="liBox2">
                                        <p className="title2">智慧能源解决方案</p>
                                    </div>
                                </Link>
                                <Link to="/community">
                                    <div className="liBox2">
                                        <p className="title2">智慧社区解决方案</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <p className="p"> 公司介绍</p>
                            <div className="solvebox">
                                 <Link to="/about">
                                <div className="liBox2">
                                    <p className="title2">企业概况</p>
                                </div>
                                </Link>
                                <Link to="/about">
                                <div className="liBox2">
                                    <p className="title2">荣誉资质</p>
                                </div>
                                </Link>
                                <Link to="/contact">
                                <div className="liBox2">
                                    <p className="title2">联系我们</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <p className="p"> 联系我们</p>
                            <div className="contactbox">
                                {
                                    contactList.map((item) => {
                                        return (
                                            <div key={item.name} className="contentBox">
                                                <p className="title3">{item.name}</p>
                                                <div className="cont">
                                                    {item.content}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="lineWrap"></div>
                    <div className="bottomBox">
                        <a href="https://beian.miit.gov.cn/" className="a">版权所有ICP证 ：蜀ICP备2021024956号</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
