import React, { Component } from 'react'
import './solve.scss'
export default class UsageScenarios extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collection: true,
      control: false,
      follow: false,
      service: false,
      activeIndex:0
    }
  }
  handleChange = (id,index, e) => {
    console.log(id, e, 52);
    this.setState({
      activeIndex:index
     })
    if (id === 1) {
      this.setState({
        collection: true,
        control: false,
        follow: false,
        service: false
      })
    }
    if (id === 2) {
      this.setState({
        collection: false,
        control: true,
        follow: false,
        service: false
      })
    }
    if (id === 3) {
      this.setState({
        collection: false,
        control: false,
        follow: true,
        service: false
      })
    }
    if (id === 4) {
      this.setState({
        collection: false,
        control: false,
        follow: false,
        service: true
      })
    }
  }
  render() {
    // console.log(this.props, 5);
    return (
      <div className='UsageScenarios2'>
        <p className='title'>使用场景</p>
        <div className='UseContent'>
          <div className='nameWrap'>
            {
              this.props.nameList.map((item,index) => {
                return (
                  <div className='Carousel' key={item.id} onMouseEnter={(e) => this.handleChange(item.id,index, e)}>
                    <div className={`titWrap ${this.state.activeIndex===index?'active':' '}`}>
                      <div className='tit'>{item.name}</div>
                      <div className='tipContent'>
                        {item.content}
                      </div>
                    </div>

                  </div>

                )
              })
            }
          </div>

          <div>
            {
              this.state.collection ? (<div className='contWrap' >
                {/* <div className='san' /> */}
                <div className='leftBox'>
                  <img src={this.props.nameList[0].img} alt="" />
                </div>

              </div>) : ''
            }
            {
              this.state.control ? (<div className='contWrap'>
                {/* <div className='san2' /> */}
                <div className='leftBox'>
                  <img src={this.props.nameList[1].img} alt="" />
                </div>
              </div>) : ''
            }
            {
              this.state.follow ? (<div className='contWrap'>
                {/* <div className='san3' /> */}
                <div className='leftBox'>
                  <img src={this.props.nameList[2].img} alt="" />
                </div>
              </div>) : ''
            }
            {
              this.state.service ? (<div className='contWrap'>
                {/* <div className='san4' /> */}
                <div className='leftBox'>
                  <img src={this.props.nameList[3].img} alt="" />
                </div>
              </div>) : ''
            }
          </div>
        </div>
      </div>
    )
  }
}
