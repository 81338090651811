import React, { Component } from 'react'
import Images from "../../assets/ProductService/jiagoutu.png"
import Banner from "../../components/Banner"
import Introduce from "../../components/service/ProductArchitecture"
import Characteristics from "../../components/service/ProductCharacteristics"
import Advantages from "../../components/service/ProductAdvantages"
import Use from "../../components/service/UsageScenarios"
import backGround from "../../assets/bg-topC.png";
const bannerStyle={
  width: "100%",
  backgroundImage: `url(${backGround})`,
  backgroundSize: '100% 100%',
  backgroundRepeat:'no-repeat'
}
const backColor = {
  width: "100%",
  backgroundColor: '#F0F5FA'
}
export default class CollaborativeGovernance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerContent: {
        tit: '智慧社区协同治理平台',
        cont: '社区协同治理平台，是面向社区综合治理、社区服务运营开发的综合性服务平台。'
      },
      //功能特性
      contentList: [
        {
          id: '01',
          tit: '党群服务',
          tip: '平台构建社区党组织、居民党组织、物业党组织，包括党员报到、组织生活、先锋榜样、学习园地、党员服务站等在内的完善功能架构。在党建引领下，构建起完善的居民自治组织组建、运营和指导服务功能，并通过社区活动的组织运营，联动党群关系。',
        },
        {
          id: '02',
          tit: '社情感知',
          tip: '社区协同治理平台以物联为基础，通过与服务区域内门禁系统、车人行道闸、监控视屏、感知传感器等的对接，实时感知和智能识别包括火灾预警、安全防控、设备故障、通道占用、高空抛物、人群聚集等异常事件，有效提升社区、小区运营管理效率和有效性。',
        },
        {
          id: '03',
          tit: '资源联动',
          tip: '社区协同治理平台，以平台能力集成丰富的社区物业、商业及社区服务运营模式，可根据服务社区需要为区域内中小物业机构、社商群体及社会组织、志愿者群体，提供线上运营和服务模式，实现社区资源的联动和整合，实现社区服务运营新生态的构建。',
        },
        {
          id: '04',
          tit: '管理调度',
          tip: '通过事件感知和资源联动赋予社区管理者更为有效的管理调度能力。等常态化例行事件，通过规则引擎自动触发工单指令，实现智能化调度；对紧急事件、异常事件实时报知对应管理人员和责任人员，通过调度社区各级各类资源，实现快速处置，极大的提升管理效率。',
        }
      ],
      //产品优势
      tipList: [
        {
          tit: '社群与活动运营',
          tip: '智慧社区居民端整合社区活动运营功能，为社区兴趣团队、居民意见领袖等提供线上化的服务支持，并通过社区活动供应商的招募引入，建立丰富多彩的社区活动。',
          img: require('../../assets/aadvantage/4.png')
        },
        {
          tit: '规范员工与运作管理      ',
          tip: '“物业云平台+社区服务中心” 为区域内中小物业提供包括员工管理、计划运营、工单调度在内完善的信息化运作管理平台支撑，配合物联平台能力补足小区服务管理功能、提升物业运营管理效率、实现服务管理标准化。     ',
          img: require('../../assets/aadvantage/5.png')
        },
        {
          tit: '创新基础服务资源调度',
          tip: '对区内包括经开物业、旌湖星辰天虹物业、电信佳园新泰物业等品质物业机构，通过接入智慧社区云物业可以实现一线运营和集团管理成本优化，并可作为中心物业通过智科云鑫社区云物业运营模式向周边小区提供物业服务输出 。     ',
          img: require('../../assets/aadvantage/6.png')
        },
        {
          tit: '共有资源管理',
          tip: '智科云鑫通过居民组织服务平台，实现小区经营性资源盘点和建立有效的经营管理。 ',
          img: require('../../assets/aadvantage/7.png')
        },
        {
          tit: '社区云管家服务 ',
          tip: '针对老旧小区服务资源薄弱，制约居民社区生活品质提升的问题，智科云鑫通过居民服务小程序部署“社区云管家服务”，居民通过小程序一键呼叫，即可通过社区管家对接专业服务资源。    ',
          img: require('../../assets/aadvantage/8.png')
        },
        {
          tit: '社区多元化服务整合  ',
          tip: '智慧社区物业云通过居民小程序提供物业基础服务的同时，通过社区商家整合提供社区多元化服务，为中小物业提供增值服务渠道。  ',
          img: require('../../assets/aadvantage/9.png')
        }
      ],
      //使用场景
      nameList: [
        {
          id: 1,
          name: '社区信息采集',
          content: '社区协同治理平台通过与智能化设备互联，与物业机构、商家等的互动和系统打通，能获取包括建筑、房屋、设备、居民等的静态数据和设备运行、车辆出入、居民活动等实时动态数据，通过社区治理数据库为社区信息采集痛点赋能减负。',
          img:require('../../assets/ProductService/1Scenarios.jpg')
        },
        {
          id: 2,
          name: '社区安全防控',
          content: '平台联动覆盖区域智能设备、监控摄像头等，协助社区安防人员实现模拟巡更巡检，并可自动识别各类安防异常事件的发生，通过规则引擎预配置，根据事件类别和等级实时报知相应的责任人，并在社区管控大屏显示，预防火警、安全事件发生，对应急事件快速响应。',
          img:require('../../assets/ProductService/2Scenarios.jpg')
        },
        {
          id: 3,
          name: '特殊人群关注',
          content: '通过联动人脸识别设备，可实现特殊关注人群出入记录和轨迹跟踪。根据不同人群关注、服务的需要，通过规则引擎配制触发规则，如在一定时间内未记录到独居高龄人员出入和园区活动，平台可自动派单网格员上门探访服务。',
          img:require('../../assets/ProductService/3Scenarios.jpg')
        },
        {
          id: 4,
          name: '商业物业服务',
          content: '中小物业和社区商家可申请平台服务，入驻平台后获取包括物业收缴费、物业报事报修、任务排班、工单调度、线上店铺、信息数据库等完善的线上化运营支持，提升社区服务机构运作效率、运作规范性的同时，为社区居民提供更加完善、周道的服务保障。',
          img:require('../../assets/ProductService/4Scenarios.jpg')
        }
      ]
    }
  }
  componentDidMount() {
    // 返回顶部
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='CollaborativeGovernance'>
        {/* 导航部分 */}
        <div style={bannerStyle}>
          <Banner bannerContent={this.state.bannerContent} />
        </div>
        {/* 产品介绍 */}
        <Introduce Images={Images} />
        {/* 功能特性 */}
        <div style={backColor}>
          <Characteristics contentList={this.state.contentList} />
        </div>
        {/* 产品优势 */}
        <Advantages tipList={this.state.tipList} />
        {/* 使用场景 */}
        <div style={backColor}>
          <Use nameList={this.state.nameList} />
        </div>
      </div >
    )
  }
}
