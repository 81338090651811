import Home from './view/home'
import Case from './view/case'
import Solve from './view/solve'
import Service from './view/service'
import About from './view/about'
import Contact from './view/contact'
import BJlaboremployment from './view/case/BJlaboremployment'
import BJstudent from './view/case/BJstudent'
import DYsmartCommunity from './view/case/DYsmartCommunity'
import { Routes, Route, Navigate } from 'react-router-dom'
import Top from "./components/Top";
import Footer from "./components/Footer";
import CollaborativeGovernance from "./view/service/CollaborativeGovernance"
import EmploymentRisk from "./view/service/employmentRisk"
import IotPerception from"./view/service/iotPerception"
import SecurityManagement from "./view/service/securityManagement"

import Campus from"./view/solve/campus"
import Community from "./view/solve/community"
import Energy from"./view/solve/energy"
import GovernmentAffairs from "./view/solve/governmentAffairs"
import Internet from"./view/solve/internet"
function App() {
  return (
    <div>
      <Top />
      <Routes>
        {/* <Route path='/' element={<Home />} ></Route>solc */}
        <Route path='/home' element={<Home />} />
        <Route path='/case' element={<Case />} />
        <Route path='/solve' element={<Solve />} />s
        <Route path='/service' element={<Service />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='*' element={<Navigate to="/home" />} />
        <Route path='/BJlaboremployment' element={<BJlaboremployment />} />
        <Route path='/BJstudent' element={<BJstudent />} />
        <Route path='/DYsmartCommunity' element={<DYsmartCommunity />} />
        {/* 产品服务 */}
        <Route path="/CollaborativeGovernance" element={<CollaborativeGovernance /> }/>
        <Route path="/employmentRisk" element={<EmploymentRisk />} />
        <Route path="/iotPerception" element={<IotPerception />} />
        <Route path="/securityManagement" element={<SecurityManagement/>} />

        {/* 解决方案 */}
        <Route path="/campus" element={<Campus /> }/>
        <Route path="/community" element={<Community />} />
        <Route path="/energy" element={<Energy />} />
        <Route path="/governmentAffairs" element={<GovernmentAffairs/>} />
        <Route path="/internet" element={<Internet/>} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
