import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import backGround from "../../assets/case.png";
// import Logo from "../../assets/BJlaboremployment.png";
import exhibitionImg from "../../assets/BJstudent.png"
import './index.scss'
//组件
import Banner from "../../components/Banner";
import backImage from "../../assets/jingdian.jpg"
const backColor = {
  width: "100%",
  backgroundImage: `url(${backImage})`,
  backgroundSize: '100% 100%',
}
const bannerContent={
  tit: '经典案例',
        cont: '推动行业客户数字化转型，提升企业核心生产业务效率'
}
export default class BJstudent extends Component {
  render() {
    return (
      <div className='BJlaboremployment'>
       <div style={backColor}>
        <Banner bannerContent={bannerContent} backImage={backImage}/>
        </div>
        <div className='gotoPage'>
          <Link to="/case"> 经典案例&gt;</Link> 学生综合服务平台
        </div>
        <div className='project'>
          <div className='leftWrap'>
            {/* <div className='img'>
              <img src={Logo} alt="" />
            </div> */}
            <div className='title'>
              综合服务平台
            </div>
          </div>

          <div className='introduce'>
            <p className='tit'>
              学生综合服务平台
            </p>
            <div className='tip'>
            通过物联设备监测控制、学校管理系统业务数据整合，实现学校数据互联互通，协同应用.
            </div>
          </div>
        </div>
        <div className='describe'>
        学生综合服务平台主要围绕学校管理、个性化服务、校园安全、绿色低碳等场景建设，通过物联设备监测控制、学校管理系统业务数据整合，实现学校数据互联互通，协同应用，XX学生综合服务平台包含了学校管理、平安校园、教学管理、公共服务、绿色校园等几大功能模块建设。对提升学校管理质量和效率，增强师生幸福感、获得感、安全感有积极意义。
        </div>
        <div className='Exhibition'>
          <img src={exhibitionImg} alt="" />
        </div>
      </div>
    )
  }
}
