import React, { Component } from 'react'
import Images from "../../assets/ProductService/shuzihua.png"
import Banner from "../../components/Banner"
import Introduce from "../../components/service/ProductArchitecture"
import Characteristics from "../../components/service/ProductCharacteristics"
import Advantages from "../../components/service/ProductAdvantages"
import Use from "../../components/service/UsageScenarios"
import backGround from "../../assets/bg-topC.png";
const backColor = {
  width: "100%",
  backgroundColor: '#F0F5FA'
}
const bannerStyle={
  width: "100%",
  backgroundImage: `url(${backGround})`,
  backgroundSize: '100% 100%',
  backgroundRepeat:'no-repeat'
}
export default class securityManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerContent: {
        tit: '数字化校园管理平台',
        cont: '通过将智慧物联设备采集的数据，与学校学生管理系统数据进行集成整合，全面整合业务应用与数据，数据实现互联互通，在建设平安校园的同时，为师生提供一站式管理服务，提升学校管理效率和质量。增强师生获得感。'
      },
      //  功能特性
      contentList: [
        {
          id: '01',
          tit: '多端协同管理',
          tip: '校园管理平台支持校园内不同角色（教师、学生）应用需求，同时提供移动端和PC端同步支撑。'
        },
        {
          id: '02',
          tit: '多方集成接入',
          tip: '在校园教务管理的基础上，为了更好的为学生提供服务，系统以校友为中心，构建校友圈，同步整合校园合作企业，为学生就业，学生交流提供在线平台'
        },
        {
          id: '03',
          tit: '多内容服务',
          tip: '数字化校园管理平台，除开日常教务，学生管理工作以外，同步提供心理资讯，企业入住，招聘就业等增值服务'
        },
        {
          id: '04',
          tit: '信息联动',
          tip: '通过整合教务与学生的日常业务，能够不受地域，不受时间限制，为在校学生管理提供方面，实现信息的实时有效沟通'
        }
      ],
      //产品优势
      tipList: [
        {
          tit: '提高效率、规范流程',
          tip: '系统提供的强大检索体系和报表的自动生成功能大大了降低了业务强度，提升了业务处理效率，规范各项业务流程系统提供的强大检索体系和报表的自动生成功能大大了降低了业务强度，提升了业务处理效率，规范各项业务流程 ',
          img:require('../../assets/aadvantage/10.png')
        },
        {
          tit: '虚拟空间、跨地域管理      ',
          tip: '一信息资源和信息分析为核心,完成数字化校园的学习、教育、 办公等业务,创立了虚拟的管理空间',
          img:require('../../assets/aadvantage/11.png')
        },
        {
          tit: '一站式服务',
          tip: '集成其他各类应用子系统(心理平台),在平台登录一次后,用户就能访问其他所有的授权应用而无需再次登录 。     ',
          img:require('../../assets/aadvantage/12.png')
        },
        {
          tit: '架构简单便捷',
          tip: '采用微服务架构,具有部署简单稳定性强、接受度高、成本更低等诸多优势 ',
          img:require('../../assets/aadvantage/13.png')
        },
        
      ],
      //使用场景
      nameList: [
        {
          id: 1,
          name: '平安校园',
          content: '针对学院的特点，充分考虑业务应用系统集成，依靠先进的设备和科学的管理，利用行业最新技术，将计算机技术、自动控制技术、通讯与信息处理技术等先进技术相结合，应用适度超前的先进、适用、优化集成的成套技术体系和成熟的设备体系，创造一个人性化、安全、舒适、方便、快捷、开放的环境，最终实现技术防范与人力防范、实体防范相结合的目标。',
          img:require('../../assets/ProductService/6Scenarios.jpg')
        },
        {
          id: 2,
          name: '绿色校园',
          content: '通过物联设备系统集成监测，设备智能控制构建节能场景。让师生享受绿色、低碳生活。享受先进技术带来得便利和舒适，提升师生幸福感、获得感。',
          img:require('../../assets/ProductService/5Scenarios.jpg')
        },
        {
          id: 3,
          name: '学校管理',
          content: '利用信息化技术，实现学校业务应用与数据整合，实现数据互联互通、业务协同，提升学校管理效率。',
          img:require('../../assets/ProductService/4Scenarios.jpg')
        },
        {
          id: 4,
          name: '大数据构建师生画像，帮助科学决策',
          content: '学生入校建立学习成长大数据档案，建立教师成长档案，大数据建立学生活像师生画像，辅助教师、学生、教务建立科学合理的管理规划决策。',
          img:require('../../assets/ProductService/3Scenarios.jpg')
        }
      ]
    }
  }
  componentDidMount() {
    // 返回顶部
    window.scrollTo(0, 0);
  }
 
  render () {
    return (
      <div className='securityManagement'>
        {/* 导航部分 */}
        <div style={bannerStyle}>
          <Banner bannerContent={this.state.bannerContent} />
        </div>
        {/* 产品介绍 */}
        <Introduce Images={Images} />
        {/* 功能特性 */}
        <div style={backColor}>
          <Characteristics contentList={this.state.contentList} />
        </div>
        {/* 产品优势 */}
        <Advantages tipList={this.state.tipList} />
        {/* 使用场景 */}
        <div style={backColor}>
          <Use nameList={this.state.nameList} />
        </div>
        </div>
    )
  }
}
