import React, { Component } from 'react'
import Images from "../../assets/ProductService/jiankong.png"
import Banner from "../../components/Banner"
import Introduce from "../../components/service/ProductArchitecture"
import Characteristics from "../../components/service/ProductCharacteristics"
import Advantages from "../../components/service/ProductAdvantages"
import Use from "../../components/service/UsageScenarios"
import backGround from "../../assets/bg-topC.png";

const backColor = {
  width: "100%",
  backgroundColor: '#F0F5FA'
}
const bannerStyle={
  width: "100%",
  backgroundImage: `url(${backGround})`,
  backgroundSize: '100% 100%',
  backgroundRepeat:'no-repeat'
}
export default class employmentRisk extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerContent: {
        tit: '用工风险监测平台',
        cont: '实现了动态更新裁员风险企业名单，异常经营企业、劳资纠纷隐患、行业风险预警、重点群体关注、预警处置、政策资讯、指数管理等需求，促进了风险监测处置工作的数字化、全景化、一体化、智能化、专业化。'
      },
      contentList: [
        {
          id: '01',
          tit: '企业风险识别能力',
          tip: '通过对企业经营数据的实时监控，能够有效的识别出企业存在的风险信息，并且通过与人社局的协作，触发企业风险预警。'
        },
        {
          id: '02',
          tit: '风险企业管控预警能力',
          tip: '通过对企业风险信息识别，设置了风险预警，企业调查核实，企业联动处置，并且整合PC和移动端实现对风险企业的处置。'
        },
        {
          id: '03',
          tit: '多元化数据处理',
          tip: '支持接入第三方企业数据库，并且对企业数据中重点关注维度，进行分析处理，对外输出数据可视化报表、多维度的动态数据图标展示。'
        },
        {
          id: '04',
          tit: '多端业务协同',
          tip: '用工监测开通多种不同平台业务承载能力，包括pc端以及移动端，方便业务人员能够通过随时，随地掌握自身业务处理情况。'
        }
      ],
      tipList: [
        {
          tit: '数据汇聚，实时监测',
          tip: '平台通过数据采集，对企业社保、用工等信息进行标签化管理，建立企业画像，大大提高了监管效率。',
          img:require('../../assets/aadvantage/1.png')
        },
        {
          tit: '市区劳动关系风险预警分析      ',
          tip: '“物业云平台+社区服务中心” 以早发现、早预防、早处置为原则，通过实时监控，系统自动预警，有效监测企业经营异常情况和潜在风险。掌握企业裁员风险，并提供预警后介入，指导企业完善裁减员方案。形成处置闭环。     ',
          img:require('../../assets/aadvantage/2.png')
        },
        {
          tit: '大数据分析，科学决策',
          tip: '大数据平台的模型和多纬度数据分析，大大提升风险监测的及时性和准确性，而且数据量越大、数据纬度越多，预警就越有效。     ',
          img:require('../../assets/aadvantage/3.png')
        }
      ],
      nameList: [
        {
          id: 1,
          name: '仪表盘',
          content: '要是针对运维、市人社、区县仪表盘内容进行整理',
          img:require('../../assets/ProductService/6Scenarios.jpg')
        },
        {
          id: 2,
          name: '企业管理',
          content: '包括风险企业、风险信息、风险维度、监测管理等核心功能',
          img:require('../../assets/ProductService/2Scenarios.jpg')
        },
        {
          id: 3,
          name: '处置中心',
          content: '主要对风险企业处置过程记录，包括调查核实、联动处置核心功能',
          img:require('../../assets/ProductService/3Scenarios.jpg')
        },
        {
          id: 4,
          name: '报表中心',
          content: '包括风险报表、预警报表、裁员报告、区县报告等核心功能',
          img:require('../../assets/ProductService/4Scenarios.jpg')
        },
        {
          id: 5,
          name: '资讯中心',
          content: '功能主要对资讯、政策、指数进行管理',
          img:require('../../assets/ProductService/5Scenarios.jpg')
        }
      ]
    }
  }
  componentDidMount() {
    // 返回顶部
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className='employmentRisk'>
       {/* 导航部分 */}
       <div style={bannerStyle}>
       <Banner bannerContent={this.state.bannerContent} />
       </div>
        {/* 产品介绍 */}
        <Introduce Images={Images} />
        {/* 功能特性 */}
        <div style={backColor}>
          <Characteristics contentList={this.state.contentList} />
        </div>
        {/* 产品优势 */}
        <Advantages tipList={this.state.tipList} />
        {/* 使用场景 */}
        <div style={backColor}>
          <Use nameList={this.state.nameList} />
        </div>

      </div>
    )
  }
}
