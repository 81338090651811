// React:框架核心包
// ReactDOM：专门做渲染的相关的包
import React from 'react';
import ReactDOM from 'react-dom/client';
// 应用的全局文件
import './index.css';
// 根组件
import App from './App';
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
        <App />
  </BrowserRouter>
  // </React.StrictMode>
);
 
