import React, { Component } from 'react'
import './solve.scss'

export default class ProductArchitecture extends Component {
  render() {
    // console.log(this.props.Images);
    return (
      <div className='SchemeArchitecture'>
        <p className='title'>业务方案</p>
        <div className='ProductArchitecturecontent'>
          <img src={this.props.Images} alt="" />
        </div>
      </div>
    )
  }
}
