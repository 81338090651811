import Banner from "../../components/Banner";
import ProductArchitecture from "../../components/service/ProductArchitecture";
import ProductAdvantages from "../../components/service/ProductAdvantages"
import UsageScenarios from "../../components/service/UsageScenarios"
import backGround from"../../assets/service.png";
import { useEffect  } from 'react'

const backImg={
  width: "100%",
  // height:'100%',
  backgroundImage: `url(${backGround})`,
  backgroundSize: '100% 100%',
  paddingTop:'64px'
}
const backColor={
  width: "100%",
  backgroundColor:'#F5F9FC'
}
const Service =()=> {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <div style={{width:'100%'}}>
      <div style={backImg}>
        <Banner />
      </div>
      <ProductArchitecture />
      <div style={backColor}>
        <ProductAdvantages />
      </div>
      <UsageScenarios />
    </div>
  );
}

export default Service;
