import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import player from '../../assets/campus/1.mp4'

//组件
import Images from "../../assets/Solution/xiaoyuanjiagou.png"
import Banner from "../../components/Banner"
import CasePain from '../../components/solve/casePain'
import Introduce from "../../components/solve/SchemeArchitecture"
import SchemeArchitecture from "../../components/solve/SchemeAdvantages"

import backGround from "../../assets/bg-topS.png";
const bannerStyle={
  width: "100%",
  backgroundImage: `url(${backGround})`,
  backgroundSize: '100% 100%',
  backgroundRepeat:'no-repeat'
}

const backColor = {
  width: "100%",
  backgroundColor: '#F5F9FC'
}

export default class campus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerContent: {
        tit: '数字化校园解决方案',
        cont: '助力校园智能化、数字化、智慧化管理，提升学校管理水平，提升师生满足感、幸福感、获得感。通过能源监控，能耗控制、促进教育生态和社会可持续发展平衡。'
      },
      casePain: [
        {
          img: require('../../assets/Solution/1.png'),
          tit: '日常事务管理手段单一',
          cont: '目前高校的教务管理工作手段单一，基本上围绕辅导员角色完成对上对下的连接处理，并且缺乏信息化的手段，对于学生的轨迹难易管理'
        },
        {
          img: require('../../assets/Solution/2.png'),
          tit: '多种资源信息整合壁垒',
          cont: '高校掌握的不仅仅是校园内信息，同时也掌握着大量与校园合作的信息，因此需要提供一个平台将多方信息整合，为学生提供更多可见的优质服务'
        },
        {
          img: require('../../assets/Solution/3.png'),
          tit: '数据信息留存检索困难',
          cont: '以往学校毕业以后，很难对学生建立电子档案，跟踪学生后续的发展情况，因此需要提供平台不仅仅关注在校学生状态，同步跟进毕业后的学生状态'
        },
        {
          img: require('../../assets/Solution/4.png'),
          tit: '校园整体情况预见性低',
          cont: '在校园管理中，对于校园整体情况，学生整体情况的可预见性缺失，因此需要在分析学生行为数据的基础上，通过对数据分析，实现对校园整体情况的预判。'
        }
      ],
      contentList: [
        {
          id: 1,
          tit: '安全防护，建立校园安全防护网',
          tip: '建设平安校园，基于数字孪生、物联网技术，实时感知校园物理空间，动态感知校园运行情况，及时及早预防校内突发安全事件，全流程处置闭环。',
          img:require('../../assets/aadvantage/22.png')
        },
        {
          id: 2,
          tit: '大数据构建师生画像，科学决策      ',
          tip: '学生入校建立学习成长档案，建立教师成长档案，大数据建立学生画像师生画像，辅助教师、学生、教务建立科学合理的管理规划决策。     ',
          img:require('../../assets/aadvantage/23.png')
        },
        {
          id: 3,
          tit: '教学方式多样，满足不同场景使用需求',
          tip: '多媒体教学、远程教学、移动教学相结合，满足不同场景使用要求。实现资源共享，如教学资源、教师资源、设施设备等。提升资源利用率。 。     ',
          img:require('../../assets/aadvantage/24.png')
        },
        {
          id: 4,
          tit: '节能减排，打造绿色校园',
          tip: '实时感知校园水电气使用情况，构建节能场景、节能策略，智能控制能源使用，促进校园绿色可持续发展。 ',
          img:require('../../assets/aadvantage/25.png')
        },
        {
          id: 5,
          tit: '信息整合，打破信息孤岛 ',
          tip: '统一身份认证，整合业务应用与数据，数据实现互联互通，为师生提供一站式管理服务。    ',
          img:require('../../assets/aadvantage/26.png')
        }
      ]
    }
  }
  componentDidMount() {
    // 返回顶部
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className='campus'>
        {/* 导航部分 */}
        <div style={bannerStyle}>
          <Banner bannerContent={this.state.bannerContent} />
        </div>
        {/* 方案痛点 */}
        <CasePain casePain={this.state.casePain} />
        {/* 业务方案 */}
        <div style={backColor}>
          <Introduce Images={Images} />
        </div>
        {/* 产品优势 */}
        <SchemeArchitecture contentList={this.state.contentList} />

        <div style={backColor}>
          <div className='UsageScenarios'>
            <p className='title'>实践案例</p>
            <div className='contenta'>
              {/* <img src={li} alt="" /> */}
              <ReactPlayer url={player} className="vidio" playing={true} controls width='100%' height='100%' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
