import React, { Component } from 'react';
import './index.scss'
import {
    ArrowRightOutlined
} from '@ant-design/icons';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
// import rightImg from "../../assets/rightImg.svg"
class Banner extends Component {
    render() {
        return (
            <div className='BannerStyles'>
                <div className='leftBox'>
                    <div className='fontBox'>
                        <p className='pStyle'>智科云鑫</p>
                        <span className='spanStyle'>
                            心思远,慧所达,以数字技术为驱动<br/>共建绿色、低碳、安全、智慧家园新体系
                        </span>
                    </div>
                    <Link to="/about">
                        <div className='buttonBox'>
                            <div className='word' >
                                <Space >
                                    <ArrowRightOutlined />
                                </Space>
                                了解更多
                            </div>
                        </div>
                    </Link>
                </div>
                {/* <div className='rightBox'>
                    <img src={rightImg} alt="" />
                </div> */}
            </div>

        );
    }
}

export default Banner;
