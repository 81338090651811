
import React, { Component } from 'react'
import './index.scss'
import ReactPlayer from 'react-player'
import player from '../../assets/solve/1.mp4'

//组件
import Images from "../../assets/ProductService/jiagoutu.png"
import Banner from "../../components/Banner"
import CasePain from '../../components/solve/casePain'
import Introduce from "../../components/solve/SchemeArchitecture"
import SchemeArchitecture from"../../components/solve/SchemeAdvantages"


import backGround from "../../assets/bg-topC.png";
const bannerStyle={
  width: "100%",
  backgroundImage: `url(${backGround})`,
  backgroundSize: '100% 100%',
  backgroundRepeat:'no-repeat'
}
const backColor = {
  width: "100%",
  backgroundColor: '#F5F9FC'
}

export default class community extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerContent: {
        tit: '智慧社区解决方案',
        cont: '智科云鑫智慧社区解决方案基于智慧物联技术和社区综合治理平台，坚持以党建为引领，服务社区综治、服务社区民生，为社区居民、社区组织、社区商家、物业机构等多元主体提供服务支持，构建未来社区综合治理与服务运营新生态。'
      },
      casePain: [
        {
          img: require('../../assets/Solution/1.png'),
          tit: '五专业服务能力',
          cont: '改造实施区域54%小区属于业委会自管（聘请个人或夫妻负责值守和保洁）或三无小区状态，且业委会以兼职服务方式为主，不具有小区专业服务能力'
        },
        {
          img: require('../../assets/Solution/2.png'),
          tit: '集中管理调度难',
          cont: '46%小区由物业公司或单位负责物业管理与服务，但分散度极高23个小区涉及22家物业公司或单位，且以小微物业为主，势必造成管理标准化和服务品质难统一，集中管理调度难'
        },
        {
          img: require('../../assets/Solution/3.png'),
          tit: '主体缺失问题',
          cont: '老旧改以基础设施设备改造完善，以及与设施设备相关运营平台和物联平台建设为主，在当前多数小区只有1-2位人员负责小区值守的现状下，后期运营侧移交和实施中，存在主体缺失的问题'
        },
      ],
      contentList: [
        {
          id: '01',
          tit: '贯彻党建引领、政府主导的管理模式',
          tip: '智科云鑫智慧社区构建线上党建，通过党组织生活、社区活动等的策划实施，实现线上线下党建联动和党群互动。同时，智慧社区通过设备互联和服务集成实时感知社情民意，通过专门渠道与政府主管部门实现信息互通，实现主管单位对相关社区资源的有效调度。',
          img:require('../../assets/aadvantage/36.png')
        },
        {
          id: '02',
          tit: '为居民提供全面完善的社区综合服务',
          tip: '智慧社区平台整合社区设备、服务资源，通过居民服务小程序为社区居民提供包括门禁通行、智能充电、报事报修、在线缴费、服务查询、一键服务呼叫等社区服务，实现社区资讯一屏获取、社区服务一键触达，便利居民社区生活，并为特殊群体订制专门服务。',
          img:require('../../assets/aadvantage/37.png')
        },
        {
          id: '03',
          tit: '为社区多元主体提供线上运营保障',
          tip: '社区生态构建还需要社区组织、居民组织、物业机构、社区商家等的多元参与。现阶段社区主体呈现小零散特征，具有强烈的线上运营需求但缺乏线上运营能力，智科云鑫通过智慧社区平台为社区参与多元主体提供线上化运营服务，构建社区服务生态实现资源整合。',
          img:require('../../assets/aadvantage/38.png')
        },
        {
          id: '04',
          tit: '全面实现网格可视化社区综合治理',
          tip: '智科云鑫智慧社区通过BIM+GIS技术综合运用，实现网格可视化的社区管理，包括建筑、居民、设备、运行、事件等综合信息实现地图化呈现，政府主管部门、社区管理人员、网格员根据权限直观掌握社区运行状态，可视化部署调度，可极大的提升管理准确性和效率。',
          img:require('../../assets/aadvantage/39.png')
        }
      ]
    }
  }
  componentDidMount() {
    // 返回顶部
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className='community'>
        {/* 导航部分 */}
        <div style={bannerStyle}>
          <Banner bannerContent={this.state.bannerContent} />
        </div>
        {/* 方案痛点 */}
        <CasePain casePain={this.state.casePain} />
        {/* 业务方案 */}
        <div style={backColor}>
          <Introduce Images={Images} />
        </div>
        {/* 产品优势 */}
        <SchemeArchitecture contentList={this.state.contentList} />
      
        <div style={backColor}>
          <div className='UsageScenarios'>
            <p className='title'>实践案例</p>
            <div className='contenta'>
              {/* <img src={li} alt="" /> */}
              <ReactPlayer url={player} className="vidio" playing={true} controls width='100%'
                height='100%' />
            </div>
          </div>
        </div>
      </div >
    )
  }
}
