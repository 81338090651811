import React, { useEffect } from 'react'
import './index.scss'
//组件
import Banner from "../../components/Banner"
import backImage from "../../assets/Contact/top-img2.jpg"
import img from "../../assets/lianxiwomen.png"
import img2 from "../../assets/email.png"
import icon from "../../assets/address.png";

const backColor = {
  width: "100%",
  backgroundColor: '#F5F9FC',
  backgroundImage: `url(${backImage})`,
  backgroundSize: '100% 100%',
  backgroundRepeat:'no-repeat'
}
const bannerContent = {
  tit: '联系我们',
  cont: '欢迎了解更多有关智科云鑫产品服务、解决方案等信息'
}
const Case = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ContactUs">
      <div style={backColor}>
        <Banner bannerContent={bannerContent} />
      </div>
      <div className='centerWrap'>
        <div className='totalWrap'>
          <div className="smallWrap">
            <div className='leftBox'>
              <img src={img} alt="" />
            </div>
            <div className='rightBox'>
              <div className="titleWrap">
                联系电话
              </div>
              <div className="contentWrap">
                028-65732103
              </div>
            </div>

          </div>
          <div className="smallWrap">
            <div className='leftBox'>
              <img src={img2} alt="" />
            </div>
            <div className='rightBox'>
              <div className="titleWrap">
                公司邮箱
              </div>
              <div className="contentWrap">
              support@iyunxin.cn
              </div>
            </div>
          </div>
        </div>


      </div>

      <div className='address'>
        <div className='words'>

          <div className='rightWrap'>
            <div className='tit'>
              公司地址
            </div>
            <div className='nameWrap'>
              <div className='img'>
                <img src={icon} alt="" />
              </div>
              <div className='name'>成都高新区</div>
            </div>

            <div className='name2'>
              地址：中国（四川）自由贸易试验区成都高新区萃华路89号A座5 楼503号
            </div>
            <div className='name3'>
              邮编：610095
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Case;
