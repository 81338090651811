// 解决方案
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './index.scss'
import backImage from '../../assets/home/6.png'
const contentList = [
    {
        id: 1,
        path: '/internet',
        title: '工业互联网解决方案',
        tipList: [
            {
                id: 1,
                name: '大数据'
            },
            {
                id: 2,
                name: '云计算'
            },
        ],
        tip: '大数据',
        tip2: '云计算',
        content: '帮助大型、多制造模式、多工厂的集团型制造业管控安全、提高质量、缩短交期、提升效率、降低成本，由此提升综合竞争力，实现数智化转型。'
    },
    {
        id: 2,
        path: '/campus',
        title: '数字化校园解决方案',
        tipList: [
            {
                id: 1,
                name: '安全防护'
            },
            {
                id: 2,
                name: '科学决策'
            },
        ],
        content: '全面打通校园物理空间和信息空间。助力校园智能化、数字化、智慧化管理，提升学校管理水平，提升师生满足感、幸福感、获得感。'
    },
    {
        id: 3,
        path: '/governmentAffairs',
        title: '数字化管控解决方案',
        tipList: [
            {
                id: 1,
                name: '一体化'
            },
            {
                id: 2,
                name: '资产化'
            },
        ],
        content: '通过先进的数据服务与应用技术发现数据价值，提高数据资产化水平，形成自主知识产权，推动企业智能制造步伐、助力国企数字化转型。'
    },
    {
        id: 4,
        path: '/energy',
        title: '智慧能源解决方案',
        tipList: [
            {
                id: 1,
                name: '策略寻优'
            },
            {
                id: 2,
                name: '管理优化'
            },
        ],
        content: '智科云鑫通过智慧能源管理平台，为公共机构、企事业单位提供综合能源运营与管理解决方案，通过能源物联、能耗计量、能耗分析、策略寻优、管理优化、设备改造等综合措施，帮助公共机构、企事业单位提升能源效率、降低能耗成本，助力低碳绿色目标达成。'
    },
    {
        id: 5,
        path: '/community',
        title: '智慧社区解决方案',
        tipList: [
            {
                id: 1,
                name: '综合治理'
            },
            {
                id: 2,
                name: '服务民生'
            },
        ],
        content: '智科云鑫智慧社区解决方案基于智慧物联技术和社区综合治理平台，坚持以党建为引领，服务社区综治、服务社区民生，为社区居民、社区组织、社区商家、物业机构等多元主体提供服务支持，构建未来社区综合治理与服务运营新生态。'
    },
]
class Content extends Component {
    render() {
        return (
            <div className='ContentStyles2'>
                <p className='p'>解决方案</p>
                <div className='tBox'>
                    {
                        contentList.map((item) => {
                            return (
                                <div className='sBox' key={item.id}>
                                    <div className='fontBox'>
                                        <Link to={item.path} className='title'> {item.title}</Link>
                                        <div style={{ display: 'flex' }}>
                                            {item.tipList.map((cot) => {
                                                return (
                                                    <span key={cot.id}>{cot.name}</span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className='behindBox' >
                                        <div className='bg'>
                                            <div className='font'>
                                                <Link to={item.path} className='title'> {item.title}</Link>
                                                <div className='contentWrap'>
                                                    {item.content}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                </div>
                <div className='img'>
                        <img src={backImage} alt="" className='backImage' />
                    </div>
            </div >
        );
    }
}

export default Content;
