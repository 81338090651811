import React, { Component } from 'react';
import './public.scss'
// import backGround from "../assets/bg-top.png";
// const backImg = {
//     width: "100%",
//     height: "440px",
//     backgroundImage: `url(${backGround})`,
//     backgroundSize: '100% 100%',
//     paddingTop: '64px'
//   } 
class Banner extends Component {
  componentDidMount(){
    // eslint-disable-next-line no-unused-expressions
    console.log(document.getElementById("banner").style.width,14)
  }
    render() {
        return (
            <div>
            <div className="bannerContent"  id='banner'>
              <div className="leftBox">
                <div className="fontBox">
                  <p className="pStyle">{this.props.bannerContent.tit}</p>
                  <span >
                    {this.props.bannerContent.cont}
                  </span>
                </div>
              </div>
              {/* <div className='rightBox'>
                <img src={this.props.backImage} alt="" />
              </div> */}
            </div>
          </div>
        );
    }
}

export default Banner;
